<template>
	<div class="exception-body  error">
		<div class="exception-text">
			<div class="exception-box">
				<span>Error</span>
			</div>
			<span> Occured</span>
		</div>
		<div class="exception-panel">
			<div class="exception-image">
				<img src="layout/images/exception/icon-error.png" alt="avalon-vue"/>
			</div>
			<div class="exception-panel-content">
				<div class="information-text">
					<h3>An error occured.</h3>
					<p>Please contact system administrator.</p>
				</div>
				<router-link to="/">
					<Button label="Go To Dashboard" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	
</script>

<style scoped>

</style>