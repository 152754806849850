<template>
	<div class="exception-body notfound">
		<div class="exception-text">
			<div class="notfound-box">
				<span>404</span>
			</div>
			<span class="notfound-text"> Page Not Found</span>
		</div>
		<div class="exception-panel">
			<div class="exception-image">
				<img src="layout/images/exception/icon-error.png" alt="avalon-vue"/>
			</div>
			<div class="exception-panel-content">
				<div class="information-text">
					<h3>That wasn’t supposed to happen...</h3>
					<p>Better get back home and forget about that</p>
				</div>
				<router-link to="/">
					<Button label="Go To Dashboard" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	
</script>

<style scoped>

</style>